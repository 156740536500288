import React, {
  ForwardRefRenderFunction,
  useRef, forwardRef, useImperativeHandle,
  useState,
  useEffect,
} from 'react';
import BrainHeardImg from '~/assets/images/brain-heart.svg';
import Modal from './Modal';
import styles from './splash-screen.module.scss';

export type ModalTimeslotExportHandle = {
  open: () => void,
  close: () => void;
};

const ModalTimeslots: ForwardRefRenderFunction<
ModalTimeslotExportHandle, { handleClose?: () => void }> = ({
  handleClose,
}, ref) => {
  const modalRef = useRef<any>(null);
  const timerRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      modalRef.current.open();
      setIsOpen(true);
    },
    close: () => {
      modalRef.current.close();
      setIsOpen(false);
    },
  }), []);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      modalRef.current.close();
      setIsOpen(false);
    }, 3000);
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [isOpen, timerRef.current]);

  return (
    <Modal
      ref={modalRef}
      handleClose={() => {
        if (typeof handleClose === 'function') handleClose();
      }}
    >
    <div className={styles.splash}>
      <div className={styles.content}>
        <img src={BrainHeardImg} />
        <p className={styles.greetings}>
          Merci pour votre confiance<br />et à bientôt !
        </p>
      </div>
    </div>
    </Modal>
  );
};

export default forwardRef(ModalTimeslots);
