import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext, useController } from 'react-hook-form';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import { useGetSession } from '~/hooks/sessions';
import { useCreateAspirations } from '~/hooks/aspirations';
import happyFeelings from '~/constants/happy-feelings';
import sadFeelings from '~/constants/sad-feelings';
import angryFeelings from '~/constants/angry-feelings';
import confusedFeelings from '~/constants/confused-feelings';
import disgustedFeelings from '~/constants/disgusted-feelings';
import surprisedFeelings from '~/constants/surprised-feelings';
import tiredFeelings from '~/constants/tired-feelings';
import worriedFeelings from '~/constants/worried-feelings';
import sereneFeelings from '~/constants/serene-feelings';

import styles from './optional-feelings.module.scss';

const OptionalFeelings = () => {
  const navigate = useNavigate();
  const { control, watch } = useFormContext();
  const { data: session } = useGetSession('', false);
  const watchFeeling = watch('feeling');

  const {
    mutate: createAspirations,
  } = useCreateAspirations();

  const { field: { onChange, value } } = useController({
    name: 'optionalFeeling',
    control,
  });

  const handleNextStep = () => {
    if (!session) return;
    createAspirations({
      sessionId: session._id,
      input: session?.situation || '',
      rewording: session?.rewording || '',
    });
    navigate('/form-1/quote');
  };

  return (
    <>
      <div className={styles.optionalFeelings}>
        <div className={styles.header}>
          <p>
            Préciser l&apos;émotion
          </p>
          <h2>{watchFeeling}</h2>
        </div>
        <div>
          {watchFeeling === 'Joyeux.se' && (
            <ul className={value ? styles.withValue : ''}>
              {happyFeelings.map((d: string, i: number) => (
                <li className={value === d ? styles.selected : ''} key={`sad-feelings-${i}`} onClick={() => onChange(d)}>
                  <p>{d}</p>
                </li>
              ))}
            </ul>
          )}
          {watchFeeling === 'Serein.e' && (
            <ul className={value ? styles.withValue : ''}>
              {sereneFeelings.map((d: string, i: number) => (
                <li className={value === d ? styles.selected : ''} key={`serene-feelings-${i}`} onClick={() => onChange(d)}>
                  <p>{d}</p>
                </li>
              ))}
            </ul>
          )}
          {watchFeeling === 'Triste' && (
            <ul className={value ? styles.withValue : ''}>
              {sadFeelings.map((d: string, i: number) => (
                <li className={value === d ? styles.selected : ''} key={`sad-feelings-${i}`} onClick={() => onChange(d)}>
                  <p>{d}</p>
                </li>
              ))}
            </ul>
          )}
          {watchFeeling === 'En colère' && (
            <ul className={value ? styles.withValue : ''}>
              {angryFeelings.map((d: string, i: number) => (
                <li className={value === d ? styles.selected : ''} key={`angry-feelings-${i}`} onClick={() => onChange(d)}>
                  <p>{d}</p>
                </li>
              ))}
            </ul>
          )}
          {watchFeeling === 'Confus.e' && (
            <ul className={value ? styles.withValue : ''}>
              {confusedFeelings.map((d: string, i: number) => (
                <li className={value === d ? styles.selected : ''} key={`confused-feelings-${i}`} onClick={() => onChange(d)}>
                  <p>{d}</p>
                </li>
              ))}
            </ul>
          )}
          {watchFeeling === 'Dégouté.e' && (
            <ul className={value ? styles.withValue : ''}>
              {disgustedFeelings.map((d: string, i: number) => (
                <li className={value === d ? styles.selected : ''} key={`disgusted-feelings-${i}`} onClick={() => onChange(d)}>
                  <p>{d}</p>
                </li>
              ))}
            </ul>
          )}
          {watchFeeling === 'Surpris.e' && (
            <ul className={value ? styles.withValue : ''}>
              {surprisedFeelings.map((d: string, i: number) => (
                <li className={value === d ? styles.selected : ''} key={`surprised-feelings-${i}`} onClick={() => onChange(d)}>
                  <p>{d}</p>
                </li>
              ))}
            </ul>
          )}
          {watchFeeling === 'Fatigué.e' && (
            <ul className={value ? styles.withValue : ''}>
              {tiredFeelings.map((d: string, i: number) => (
                <li className={value === d ? styles.selected : ''} key={`tired-feelings-${i}`} onClick={() => onChange(d)}>
                  <p>{d}</p>
                </li>
              ))}
            </ul>
          )}
          {watchFeeling === 'Inquiet.e' && (
            <ul className={value ? styles.withValue : ''}>
              {worriedFeelings.map((d: string, i: number) => (
                <li className={value === d ? styles.selected : ''} key={`worried-feelings-${i}`} onClick={() => onChange(d)}>
                  <p>{d}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className={styles.bottom}>
        <button onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack size={50} />
        </button>
        {value && (
          <button onClick={() => handleNextStep()}>
            <IoIosArrowRoundForward size={50} />
          </button>
        )}
      </div>
    </>
  );
};

export default OptionalFeelings;
