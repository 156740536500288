import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import styles from './quote.module.scss';

const Quote = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.quote}>
        <div>
          <p>
            Nous vous invitons à accueillir l&apos;émotion en lien avec ce qui s&apos;est passé.
            Cette émotion qui vous traverse donne la mesure de ce qui compte
              vraiment pour vous à cet instant et nous allons essayer de le nommer ensemble
          </p>
          {/* <figure>
            <audio controls src=""></audio>
          </figure> */}
        </div>
      </div>
      <div className={styles.bottom}>
        <button onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack size={50} />
        </button>
        <button onClick={() => navigate('/form-1/aspirations')}>
          <IoIosArrowRoundForward size={50} />
        </button>
      </div>
    </>
  );
};

export default Quote;
