import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import { AnimatePresence, motion } from 'framer-motion';
import { useGetSession } from '~/hooks/sessions';
import { useCreateAspirations } from '~/hooks/aspirations';
import { useGetRewording } from '~/hooks/ai';
import {
  Textarea,
} from '~/lib/HooksFormFields';
import Loader from '~/components/Loader';
import styles from './situation.module.scss';
import talkImg from '~/assets/images/talk.png';

const StepSituation = () => {
  const navigate = useNavigate();
  const { control, watch, setValue } = useFormContext();
  const watchSituation = watch('situation');
  const [step, setStep] = useState(0);
  const [rewording, setRewording] = useState('');
  const { data: session } = useGetSession('', false);

  const {
    mutate: createAspirations,
  } = useCreateAspirations();

  const {
    isSuccess: isSuccessRewording,
    isLoading: isLoadingRewording,
    error,
    mutateAsync: getRewording,
  } = useGetRewording();
  const errorApi: any = error;

  const generateRewording = async () => {
    try {
      const res = await getRewording({
        input: watchSituation,
      });
      if (res.rewording) {
        setRewording(res.rewording);
        setValue('rewording', res.rewording);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitFirstStep = async () => {
    if (!session) return;
    createAspirations({
      sessionId: session._id,
      input: watchSituation,
      rewording,
    });
    navigate('/form-2/quote');
  };

  useEffect(() => {
    if (isSuccessRewording) setStep(1);
  }, [isSuccessRewording]);

  const messageError = useMemo(() => {
    let msg = '';
    if (errorApi && errorApi.response.status === 422 && errorApi.response.data.error) {
      const strError = errorApi.response.data.error;
      if (strError.includes('Invalid input: rude')) {
        msg = 'Il semblerait que le language utilisé pour décrire la situation n\'est pas correct.';
      } else if (strError.includes('Invalid input: opinionated, notation: 10/10')) {
        msg = 'La situation ne semble pas factuel.';
      } else if (strError === 'Invalid input: SAFETY') {
        msg = 'Nous ne pouvons pas traiter des requêtes contenant du contenu haineux, sexuellement explicite, du harcèlement ou du contenu dangereux. Veuillez revoir votre texte.';
      } else {
        msg = strError;
      }
    } else if (errorApi) {
      msg = 'Une problème est survenue.';
    }
    return msg;
  }, [errorApi]);

  return (
    <>
      <div className={styles.situation}>
        <h4>
          Pouvez-vous écrire la situation ou la pensée qui génère cette émotion&nbsp;?
        </h4>
        <span className={step !== 0 ? styles.disabled : ''}>
          <Textarea
            name='situation'
            placeholder='Entrer la situation ici...'
            control={control}
            maxlength={500}
            disabled={step !== 0}
            handleChange={(value: string) => {
              if (!value) setStep(0);
            }}
          />
        </span>
        {isLoadingRewording && <div className={styles.containerLoader}><Loader isWhite /></div>}
        <AnimatePresence>
          {!!messageError && (
            <motion.div
              className={styles.contentSituation}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <p>
                {messageError}
              </p>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {step !== 0 && (
            <motion.div
              className={styles.contentSituation}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <img src={talkImg} />
              <p>
              Afin de s&apos;assurer que la description ne contient pas d&apos;interprétation,
              nous vous proposons la reformulation suivante.
              </p>
              <p>
                {rewording}
              </p>
              <p>
              Cette formulation vous convient-elle ? Si non, nous vous proposons de revenir
              en arrière et de reformuler votre première description en reprenant des faits.
              </p>
            </motion.div>
          )}
        </AnimatePresence>

      </div>
      <div className={styles.bottom}>
        {(watchSituation && step !== 0) && (
          <button onClick={() => setStep(0)}>
            <IoIosArrowRoundBack size={50} />
          </button>
        )}
        {(watchSituation) && (
          <button className={styles.next} onClick={() => {
            if (step === 0) {
              generateRewording();
              return;
            }
            if (step === 1) submitFirstStep();
          }}>
            <IoIosArrowRoundForward size={50} />
          </button>
        )}
      </div>
    </>
  );
};

export default StepSituation;
