import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsMutating } from '@tanstack/react-query';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import { useGetSession } from '~/hooks/sessions';
import Loader from '~/components/Loader';
import styles from './ideas.module.scss';

const StepIdeas = () => {
  const navigate = useNavigate();
  const { data: session } = useGetSession('', false);
  const isMutatingAspirations = useIsMutating({ mutationKey: ['session-ideas'] });
  const isLoading = !!isMutatingAspirations;

  return (
    <>
      <div className={styles.ideas}>
        <h4>
          Dans cette situation, vous pourriez par exemple&nbsp;:
        </h4>
        {!isLoading && (
          <ul>
            {(session?.generatedIdeas || []).map((d: string, index: any) => (
              <li key={`idea-${index}`}>
                <div>
                  <p>{d}</p>
                </div>
              </li>
            ))}
          </ul>
        )}
        {isLoading && <div className={styles.containerLoader}><Loader isWhite /></div>}
      </div>
      <div className={styles.bottom}>
        <button onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack size={50} />
        </button>
        <button onClick={() => navigate('/form-1/celebration')}>
          <IoIosArrowRoundForward size={50} />
        </button>
      </div>
    </>
  );
};

export default StepIdeas;
