import React, { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, NavLink } from 'react-router-dom';
import { BiLock } from 'react-icons/bi';
import { FieldValues } from 'react-hook-form';
import { useSignIn } from '~/hooks/auth';
import getErrorAuthMessage from '~/utils/auth-helpers';
import SignInForm from '~/components/form/SignInForm';

import styles from './signin.module.scss';

export interface CredentialsRequest {
  email: string
  password: string
}

const SignIn = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    data, mutate: signIn, isSuccess, error,
  } = useSignIn();
  const errorApi: any = error;

  const handleSubmit = async (userInfo: FieldValues) => {
    try {
      await signIn(userInfo as CredentialsRequest);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem('token', data.token);
      queryClient.setQueryData(['user-profile'], data.user);
      navigate('/');
    }
  }, [isSuccess]);

  const errorMessage = useMemo(() => {
    if (!errorApi) return '';
    const msg = getErrorAuthMessage(errorApi?.response?.data?.error || errorApi?.response?.data);
    return msg;
  }, [errorApi]);

  return (
    <div className={styles.signin}>
      <div className={styles.container}>
        <div className={styles.containerForm}>
          <div className={styles.form}>
            <h2>Connexion</h2>
            <SignInForm
              submit={handleSubmit}
              errorMessage={errorMessage}
            />
            <div className={styles.center}>
              <NavLink to="/forgot-password">
                <BiLock size={18}/>Mot de passe oublié ?
              </NavLink>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
