import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import { useGetSession, useGenerateSessionIdeas } from '~/hooks/sessions';
import {
  Textarea,
} from '~/lib/HooksFormFields';
import styles from './willingness.module.scss';

const StepWillingness = () => {
  const navigate = useNavigate();
  const { data: session } = useGetSession('', false);
  const { control } = useFormContext();
  const [willing, setWilling] = useState('');

  const {
    mutateAsync: getSessionIdeas,
  } = useGenerateSessionIdeas();

  const nextStep = useMemo(() => {
    if (willing === 'formulation') return '/form-1/formulations';
    if (willing === 'ideas') return '/form-1/ideas';
    return '/form-1/celebration';
  }, [willing]);

  return (
    <>
      <div className={styles.willingness}>
        <h4>
        Il existe une infinité de façons de prendre soin de ses aspirations.
         Dans cette situation, que voudriez-vous faire spontanément&nbsp;?
        </h4>
        <Textarea
          name='willingness'
          placeholder='Entrer votre idée ici...'
          control={control}
        />
        <ul>
          <li >
            <div className={willing === 'formulation' ? styles.selected : ''} onClick={() => setWilling('formulation')}>
              <h4>Formuler une demande à quelqu&apos;un.e</h4>
              <p>Je souhaiterais être aidé</p>
            </div>
          </li>
          <li >
            <div className={willing === 'ideas' ? styles.selected : ''} onClick={() => {
              if (session) getSessionIdeas(session._id);
              setWilling('ideas');
            }}>
              <h4>J&apos;aimerais que HAY me donne des idées</h4>
            </div>
          </li>
        </ul>
      </div>
      <div className={styles.bottom}>
        <button onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack size={50} />
        </button>
        <button onClick={() => navigate(nextStep)}>
          <IoIosArrowRoundForward size={50} />
        </button>
      </div>
    </>
  );
};

export default StepWillingness;
