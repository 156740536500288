import React, { useMemo, useRef, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { IoIosArrowRoundBack, IoIosHelpCircleOutline } from 'react-icons/io';
import ContextForm from '~/components/ContextForm';
import styles from './step.module.scss';
import { useGetSession } from '~/hooks/sessions';

import ModalHelp from '~/components/ModalHelp';

const Form2 = () => {
  type ModalHelpHandler = React.ElementRef<typeof ModalHelp>;
  const modalHelpRef = useRef<ModalHelpHandler>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { data: session } = useGetSession('', false);

  const backgroundClassName = useMemo(() => {
    let className = styles.lines;
    if (['/form-2', '/form-2/optional-feelings'].includes(location.pathname)) className = '';
    return className;
  }, [location.pathname, session?.feeling]);

  useEffect(() => {
    if (!session) {
      navigate('/home');
    }
  }, [session]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = ''; // Pour certains navigateurs
      return ''; // Pour d'autres navigateurs
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <ModalHelp ref={modalHelpRef} />
      {session && (
        <div
          className={`${styles.steps} ${backgroundClassName}`}
        >
          <div className={styles.header}>
            <button onClick={() => navigate(-1)} className='invisible'>
              <IoIosArrowRoundBack size={50} />
            </button>
            <>{console.log(location.pathname)}</>
            {location.pathname === '/form-2/situation' && (
              <button className='invisible' onClick={() => {
                modalHelpRef.current?.open();
                console.log('here');
              }}>
                <IoIosHelpCircleOutline size={35} />
              </button>
            )}
          </div>
          <div className={styles.content}>
            <ContextForm>
              <Outlet />
            </ContextForm>
          </div>
        </div>
      )}
    </>

  );
};

export default Form2;
