import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import feelings from '~/constants/feelings';
import { ListTags } from '~/lib/HooksFormFields';
import styles from './feelings.module.scss';

const withOptionalFeelings = [
  'Serein.e',
  'Joyeux.se',
  'Triste',
  'Inquiet.e',
  'En colère',
  'Confus.e',
  'Fatigué.e',
  'Dégouté.e',
  'Surpris.e',
];

const Feelings = () => {
  const navigate = useNavigate();
  const { control, watch } = useFormContext();
  const watchFeeling = watch('feeling');

  const optionsFeelings = useMemo(() => feelings.map((f: string) => ({
    label: f,
    value: f,
  })), []);

  return (
    <>
      <div className={styles.feelings}>
        <h4>
          Comment vous sentez-vous à cet instant&nbsp;?
        </h4>
        <div className={styles.feelingsRange}>
          <p>Inconfortable</p>
          <p>Confortable</p>
        </div>
        <div>
          <ListTags
            control={control}
            name={'feeling'}
            tags={optionsFeelings}
          />
        </div>
      </div>
      <div className={styles.bottom}>
        <button onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack size={50} />
        </button>
        {watchFeeling && (
          <button onClick={() => (withOptionalFeelings.includes(watchFeeling)
            ? navigate('/form-2/optional-feelings')
            : navigate('/form-2/situation')
          )}>
            <IoIosArrowRoundForward size={50} />
          </button>
        )}
      </div>
    </>
  );
};

export default Feelings;
