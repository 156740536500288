const angryFeelings = [
  'Ne pas préciser',
  'Contrarié.e',
  'Agacé.e',
  'Frustré.e',
  'En colère',
  'Exasperé.e',
  'Revolté.e',
  'Furieux.se',
  'Hors de moi',
];

export default angryFeelings;
