/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { ISession } from '~/types/sessions';

const getSession = async (id: string) => {
  const token = localStorage.getItem('token');

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/sessions/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data.session;
};

const createSession = async (data: Partial<ISession>): Promise<{ session: ISession }> => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/sessions`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

const updateSession = async (data: Partial<ISession>): Promise<{ session: ISession }> => {
  const token = localStorage.getItem('token');

  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/sessions/${data._id}`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

const getSessionIdeas = async (id: string) => { // Generate ideas
  const token = localStorage.getItem('token');

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/sessions/suggestions/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data;
};

export {
  getSession,
  getSessionIdeas,
  createSession,
  updateSession,
};
