import React, { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useCreateSession } from '~/hooks/sessions';
import logo from '~/assets/images/logo.svg';
import talkImage from './images/talk.png';
import clarity from './images/clarity.png';
import styles from './home.module.scss';

const Home = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    mutateAsync: createSession,
  } = useCreateSession();

  const handleCreateSession = async (pathname: string) => {
    try {
      await createSession({});
      navigate(pathname);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    queryClient.setQueryData(['session'], null);
  }, []);

  return (
    <div className={styles.home}>
      <div className={styles.content}>
        <div className={styles.header}>
          <img src={logo} />
          <h3>Que pouvons-nous faire pour vous&nbsp;?</h3>
        </div>
        <div>
          <button onClick={() => navigate('/ressources')} className={`invisible ${styles.buttonBlock} ${styles.white}`}>
            <p>
              J’aimerais en savoir plus sur l’intelligence émotionnelle et relationnelle
            </p>
            <p className={styles.sub}>
              En accédant à des ressources en ligne
            </p>
          </button>
          <button onClick={() => handleCreateSession('/form-1')} className={`invisible ${styles.buttonBlock}`}>
            <img src={clarity} />
            <p>
              Je suis contrarié.e ou enthousiasmé.e par une situation
            </p>
            <p className={styles.sub}>J’aimerais être guidé.e pour l’accueillir</p>
          </button>
          <button onClick={() => handleCreateSession('/form-2')} className={`invisible ${styles.buttonBlock}`}>
            <img src={talkImage} />
            <p>J’aimerais avoir de la clarté sur mon ressenti</p>
            <p className={styles.sub}>Confortable ou pas, aidez-moi à démêler tout ça&nbsp;!</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
