const worriedFeelings = [
  'Ne pas préciser',
  'Tendu.e',
  'Inquiet.e',
  'Préoccupé.e',
  'Sur mes gardes',
  'Stressé.e',
  'Angoissé.e',
  'Effrayé.e',
  'Terrorisé.e',
];

export default worriedFeelings;
