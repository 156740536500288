/* eslint-disable max-len */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useController, useFormContext } from 'react-hook-form';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import { useGetSession } from '~/hooks/sessions';
import { Textarea } from '~/lib/HooksFormFields';
import Loader from '~/components/Loader';
import styles from './formulations.module.scss';
import { useGetRewordingRequest } from '~/hooks/ai';

const StepFormulations = () => {
  const navigate = useNavigate();
  const { data: session } = useGetSession('', false);
  const { control, watch, setValue } = useFormContext();
  const [error, setError] = useState< string | null>(null);

  const { field: { value, onChange } } = useController({
    control,
    name: 'haySuggestion',
  });

  const watchRequest = watch('request');

  const {
    isLoading,
    mutateAsync: getRewordingRequest,
  } = useGetRewordingRequest();

  const handleGetRewordingRequest = async () => {
    if (!session || !watchRequest) return;
    try {
      setError(null);
      const res = await getRewordingRequest({
        input: watchRequest,
        sessionId: session._id,
      });
      if (res.request) {
        setValue('request', res.request);
      }
    } catch (err: any) {
      setError('Une erreur est survenue.');
      console.log(err);
    }
  };

  return (
    <>
      <div className={styles.formulations}>
        <h4>
          Pour réaliser une demande, nous vous invitons à&nbsp;:
        </h4>
        <ul>
          <li>
            vérifier que votre interlocuteur.ice est disponible pour vous écouter
          </li>
          <li>
            commencer par indiquer à votre interlocuteur.ice vos aspirations
          </li>
          <li>
            veillez à ce que votre demande soit précise et limitée dans le temps
          </li>
          <li>
            avoir conscience que si votre interlocuteur.ice refuse votre
            demande c&apos;est pour répondre,
            de son côté, à une autre aspiration prioritaire pour lui.elle à cet instant
          </li>
        </ul>
        <h4>
          En cas de refus vous pourriez&nbsp;:
        </h4>
        <ul>
          <li>
            demander à votre interlocuteur.ice si il.elle peut l&apos;accepter dans
            certaines conditions
            (contenu, temporalité, etc) qui répondraient aussi à ses aspirations
          </li>
          <li>
          envisager d&apos;autres façons (qui existent en nombre infini) de répondre à votre.vos
           aspiration.s
           (en imaginer d&apos;autres ou demander des idées à HAY)
          </li>
        </ul>
        <Textarea
          name='request'
          placeholder='Lancez-vous !'
          control={control}
        />
        {error && (
          <div className={styles.error}>
            <p>{error}</p>
          </div>
        )}
        {isLoading && <div className={styles.containerLoader}><Loader isWhite /></div>}
        <span style={!watchRequest ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
          <div className={value ? `${styles.needHelp} ${styles.selected}` : styles.needHelp} onClick={() => {
            onChange(!value);
            if (session && !value) {
              handleGetRewordingRequest();
            }
          }}>
            <h4>
              Souhaitez-vous que HAY vous fasse une suggestion de reformulation ?
            </h4>
            <p>
              In fine, nous vous invitons à utiliser la formulation avec laquelle vous êtes à l&apos;aise.
              Une demande même formulée maladroitement mais réalisée avec l&apos;intention de prendre
                soin des aspirations de l&apos;autre et des vôtres a toutes ses chances d&apos;aboutir à un
                échange puis un compromis acceptable pour tou.te.s.
            </p>
          </div>
        </span>
      </div>
      <div className={styles.bottom}>
        <button onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack size={50} />
        </button>
        <button onClick={() => navigate('/form-1/celebration')}>
          <IoIosArrowRoundForward size={50} />
        </button>
      </div>
    </>
  );
};

export default StepFormulations;
