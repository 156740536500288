/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createAspirations,
} from '~/api/aspirations';

const useCreateAspirations = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      input: string,
      rewording?: string,
      sessionId: string,
    }) => createAspirations(data),
    mutationKey: ['aspirations'],
    onSuccess: async ({ session }) => {
      queryClient.setQueryData(['session'], session);
    },
  });
};

export {
  useCreateAspirations,
};
