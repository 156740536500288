/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  getSession, createSession, updateSession,
  getSessionIdeas,
} from '~/api/sessions';
import {
  ISession,
} from '~/types/sessions';

const useGetSession = (id: string, enabled: boolean) => useQuery({
  queryKey: ['session'],
  queryFn: async (): Promise<ISession | null> => getSession(id),
  keepPreviousData: true,
  enabled,
});

const useCreateSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<ISession>) => createSession(data),
    onSuccess: async ({ session }) => {
      // console.log(session);
      queryClient.setQueryData(['session'], session);
    },
  });
};

const useUpdateSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<ISession>) => updateSession(data),
    onSuccess: async ({ session }) => {
      queryClient.setQueryData(['session'], session);
    },
  });
};

const useGenerateSessionIdeas = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => getSessionIdeas(id),
    mutationKey: ['session-ideas'],
    onSuccess: async ({ session }) => {
      queryClient.setQueryData(['session'], session);
    },
  });
};

export {
  useGetSession,
  useCreateSession,
  useUpdateSession,
  useGenerateSessionIdeas,
};
