const surprisedFeelings = [
  'Ne pas préciser',
  'Surpris.e',
  'Décontenancé.e',
  'Secoué.e',
  'Abasourdi.e',
  'Sous le choc',
];

export default surprisedFeelings;
