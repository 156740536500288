import React from 'react';
import { useNavigate } from 'react-router-dom';
import BrainHeardImg from '~/assets/images/brain-heart.svg';
import styles from './intro.module.scss';

const Intro = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.intro}>
      <div className={styles.content}>
        <img src={BrainHeardImg} />
      </div>
      <div className={styles.bottom}>
        <button className='secondary' onClick={() => navigate('/mood')}>Commencer</button>
        <div>
          <a>Conditions générales</a>
        </div>
      </div>
    </div>
  );
};

export default Intro;
