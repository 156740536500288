import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
  MutationCache,
  QueryCache,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Routes from './Routes';
import './styles/app.scss';

const handleErrorRequest = (error: any) => {
  const url = error?.config?.url;
  const statusCode = error?.response?.status;
  console.log('Request url', url);
  console.log('Request statusCode', statusCode);

  if (statusCode === 401) {
    localStorage.removeItem('token');
  }

  if (statusCode === 401 && !window.location.href.includes('/login')) {
    window.location.href = '/login';
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30000,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error: any) => {
      // error
      handleErrorRequest(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => { // (error, _variables, _context, mutation)
      // if (mutation.options.onError) return;
      // console.log(error, _variables, _context, mutation);
      handleErrorRequest(error);
    },
  }),
});

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes />
        </Router>
       <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
