import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsMutating } from '@tanstack/react-query';
import { useFormContext, useController } from 'react-hook-form';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import { useGetSession } from '~/hooks/sessions';
import Loader from '~/components/Loader';
import styles from './aspirations.module.scss';

const Aspirations = () => {
  const navigate = useNavigate();
  const { data: session } = useGetSession('', false);

  const { control } = useFormContext();
  const isMutatingAspirations = useIsMutating({ mutationKey: ['aspirations'] });
  const isLoading = !!isMutatingAspirations;

  const { field: { onChange, value: values = [] } } = useController({
    name: 'aspirations',
    control,
  });

  const handleChangeAspirations = (aspiration: string) => {
    let updatedValues = [...values];
    if (updatedValues.includes(aspiration)) {
      updatedValues = values.filter((d: string) => d !== aspiration);
    } else updatedValues.push(aspiration);
    onChange(updatedValues);
  };

  return (
    <>
      <div className={styles.aspirations}>
        <div>
          {(isLoading || Object.values(session?.generatedAspirations || {}).length > 0) && (
            <>
              <h4>
                Est-ce cela que vous voudriez vivre ou avez vécu&nbsp;?
              </h4>
              <p>Nous vous invitons à sélectionner la ou les aspirations pertinentes pour vous</p>
              {isLoading && <div className={styles.containerLoader}><Loader isWhite /></div>}
              {(session?.generatedAspirations || {}).length > 0 && (
                <ul>
                  {session?.generatedAspirations.map((d: any, index: number) => (
                    <li key={`aspiration-${index}`} onClick={() => handleChangeAspirations(d.title)}>
                      <div className={values.includes(d.title) ? `${styles.aspiration} ${styles.selected}` : styles.aspiration}>
                        <h4>
                          {d.title}
                        </h4>
                        <p>
                          {d.aspiration}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
          {((session?.generatedAspirations || {}).length === 0 && !isLoading) && (
            <button onClick={() => navigate('/form-2/situation')}>Revenir l&apos;étape situation</button>
          )}
        </div>
      </div>
      <div className={styles.bottom}>
        <button onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack size={50} />
        </button>
        <button onClick={() => navigate('/form-2/willingness')}>
          <IoIosArrowRoundForward size={50} />
        </button>
      </div>
    </>
  );
};

export default Aspirations;
