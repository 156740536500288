const sadFeelings = [
  'Ne pas préciser',
  'Peiné.e',
  'Découragé.e',
  'Triste',
  'Depité.e',
  'Bouleversé.e',
  'Désespéré.e',
];

export default sadFeelings;
