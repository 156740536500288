/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const getRewording = async (data: {
  input: string,
}): Promise<any> => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/ai/rewording`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

const getRewordingRequest = async (data: {
  input: string,
  sessionId: string,
}): Promise<any> => {
  console.log(getRewordingRequest);
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/ai/rewording-request`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

export {
  getRewording,
  getRewordingRequest,
};
