import React from 'react';
import { useLocation } from 'react-router-dom';
import { LuLogOut } from 'react-icons/lu';
import { useSignOut } from '../../hooks/auth';
import styles from './navbar.module.scss';

const Navbar = () => {
  const location = useLocation();
  const signOut = useSignOut();

  const lightPages = ['/mood', '/home', '/ressources'];

  return (
    <div className={styles.navbar}>
      <div className={styles.profile}>
        {lightPages.includes(location.pathname) && (
          <button
            className='invisible'
            style={{ color: '#37715B' }}
            onClick={signOut}
          >
            <LuLogOut size={26} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Navbar;
