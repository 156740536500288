import React from 'react';
import { useController, Control } from 'react-hook-form';
import verySad from './icons/very-sad.svg';
import sad from './icons/sad.svg';
import ok from './icons/ok.svg';
import happy from './icons/happy.svg';
import veryHappy from './icons/very-happy.svg';
import verySadSelected from './icons/very-sad-selected.svg';
import sadSelected from './icons/sad-selected.svg';
import okSelected from './icons/ok-selected.svg';
import happySelected from './icons/happy-selected.svg';
import veryHappySelected from './icons/very-happy-selected.svg';
import styles from './input-mood.module.scss';

const InputMood = (
  {
    control,
    name,
    gradient = false,
    onClick,
  }
  : {
    control: Control,
    name: string,
    gradient?: boolean,
    onClick?: (mood: string) => void },
) => {
  const {
    field: {
      onChange,
      value,
    },
  } = useController({
    name,
    control,
  });

  return (
    <div className={gradient ? `${styles.container} ${styles.gradient}` : styles.gradient}>
      <div>
        <button onClick={() => {
          if (typeof onClick === 'function') onClick('very-sad');
          onChange('very-sad');
        }} className='invisible'>
          {value === 'very-sad' ? <img src={verySadSelected} /> : <img src={verySad} />}
        </button>
        <button onClick={() => {
          onChange('sad');
          if (typeof onClick === 'function') onClick('sad');
        }} className='invisible'>
          {value === 'sad' ? <img src={sadSelected} /> : <img src={sad} />}
        </button>
        <button onClick={() => {
          onChange('ok');
          if (typeof onClick === 'function') onClick('ok');
        }} className='invisible'>
          {value === 'ok' ? <img src={okSelected} /> : <img src={ok} />}
        </button>
        <button onClick={() => {
          onChange('happy');
          if (typeof onClick === 'function') onClick('happy');
        }} className='invisible'>
          {value === 'happy' ? <img src={happySelected} /> : <img src={happy} />}
        </button>
        <button onClick={() => {
          onChange('very-happy');
          if (typeof onClick === 'function') onClick('very-happy');
        }} className='invisible'>
          {value === 'very-happy' ? <img src={veryHappySelected} /> : <img src={veryHappy} />}
        </button>
      </div>
    </div>
  );
};

export default InputMood;
