import React from 'react';
import {
  useQueryClient,
} from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useSignOut } from '~/hooks/auth';
import styles from './end.module.scss';

const StepEnd = ({ showSplashScreen } : { showSplashScreen: any }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const signOut = useSignOut();

  const handleRestart = () => {
    queryClient.setQueryData(['session'], null);
    navigate('/home');
  };

  return (
    <>
      <div className={styles.end}>
        <p>
          Si une autre situation vous préoccupe
        </p>
        <a onClick={() => handleRestart()}>
          <button>
            Retourner à l&apos;écran d&apos;accueil
          </button>
        </a>
        <p>
          Pour échanger de vive voix sur une situation qui vous préoccupe, ou vous rend joyeux.se !
        </p>
        <a href='https://calendly.com/eric-ezxz/30min' target='_blank' rel="no noreferrer">
          <button>
            Programmer un rdv avec un membre de l’équipe HAY
          </button>
        </a>
      </div>
      <div className={styles.bottom}>
        <button onClick={() => {
          showSplashScreen();
          setTimeout(() => {
            signOut();
          }, 1000);
        }}>
          Quitter
        </button>
      </div>
    </>
  );
};

export default StepEnd;
