import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import feelings from '~/constants/feelings';
import { useGetSession } from '~/hooks/sessions';
import { useCreateAspirations } from '~/hooks/aspirations';
import { ListTags } from '~/lib/HooksFormFields';
import styles from './feelings.module.scss';

const withOptionalFeelings = [
  'Serein.e',
  'Joyeux.se',
  'Triste',
  'Inquiet.e',
  'En colère',
  'Confus.e',
  'Fatigué.e',
  'Dégouté.e',
  'Surpris.e',
];

const Feelings = () => {
  const navigate = useNavigate();
  const { control, watch } = useFormContext();
  const { data: session } = useGetSession('', false);
  const watchFeeling = watch('feeling');

  const {
    mutate: createAspirations,
  } = useCreateAspirations();

  const optionsFeelings = useMemo(() => feelings.map((f: string) => ({
    label: f,
    value: f,
  })), []);

  const handleNextStep = () => {
    if (!session?.situation || !session?.rewording) return;

    if (withOptionalFeelings.includes(watchFeeling)) {
      navigate('/form-1/optional-feelings');
    } else {
      createAspirations({
        sessionId: session._id,
        input: session?.situation || '',
        rewording: session?.rewording || '',
      });
      navigate('/form-1/quote');
    }
  };

  return (
    <>
      <div className={styles.feelings}>
        <h4>
          Comment vous sentez-vous en pensant à cette situation&nbsp;?
        </h4>
        <div className={styles.feelingsRange}>
          <p>Inconfortable</p>
          <p>Confortable</p>
        </div>
        <div>
          <ListTags
            control={control}
            name={'feeling'}
            tags={optionsFeelings}
          />
        </div>
      </div>
      <div className={styles.bottom}>
        <button onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack size={50} />
        </button>
        {watchFeeling && (
          <button onClick={() => handleNextStep()}>
            <IoIosArrowRoundForward size={50} />
          </button>
        )}
      </div>
    </>
  );
};

export default Feelings;
