/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useMutation,
} from '@tanstack/react-query';
import {
  getRewordingRequest,
  getRewording,
} from '~/api/ai';

const useGetRewording = () => useMutation({
  mutationFn: (data: {
    input: string,
  }) => getRewording(data),
  mutationKey: ['rewording'],
});

const useGetRewordingRequest = () => useMutation({
  mutationFn: (data: {
    input: string,
    sessionId: string,
  }) => getRewordingRequest(data),
  mutationKey: ['rewording-request'],
});

export {
  useGetRewording,
  useGetRewordingRequest,
};
