/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io';
import logo from '~/assets/images/logo.svg';
import ressources from '../ressources/data/ressources-data';
import styles from './ressources.module.scss';

const Ressources = () => {
  const { ressourceId = '0' } = useParams();
  const navigate = useNavigate();

  const ressource = useMemo(() => {
    if (!ressources) {
      return ({
        image: '',
        title: '',
        text: '',
      });
    }
    return ressources[parseInt(ressourceId, 10)];
  }, [ressources, ressourceId]);

  return (
    <div className={styles.ressources}>
      <div className={styles.content}>
        <div className={styles.header}>
          <img src={logo} />
          <button onClick={() => navigate(-1)} className='invisible'>
            <IoIosArrowRoundBack size={40} />
          </button>
        </div>
        {ressource && (
          <>
            <div className={styles.image}>
              <img src={ressource.image} />
            </div>
            <div className={styles.contentRessource}>
              <h4>{ressource.title}</h4>
              <p>{ressource.text}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Ressources;
