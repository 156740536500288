import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import { useGetSession } from '~/hooks/sessions';

import styles from './celebration.module.scss';

const StepCelebration = () => {
  const navigate = useNavigate();
  const { getValues } = useFormContext();
  const { data: session } = useGetSession('', false);
  const feeling = getValues('optionalFeeling') || getValues('feeling') || '____';
  const willingness = getValues('willingness') || session?.request || '____';
  const aspirations = getValues('aspirations') || [];

  return (
    <>
      <div className={styles.celebration}>
        <h4>
          Célébration&nbsp;!
        </h4>
        <p>
          Vous avez réalisé un parcours entier dont voici le résumé des étapes.
        </p>
        <div>
          <h6>
            La situation&nbsp;:
          </h6>
          <p>
            {session?.rewording}
          </p>
        </div>
        <div>
          <h6>Votre émotion&nbsp;:</h6>
          <p>{feeling}</p>
        </div>
        <div>
          <h6>Vos aspirations&nbsp;:</h6>
          <p>{aspirations.join(', ')}</p>
        </div>
        <div>
          <h6>L’action/demande envisagée&nbsp;:</h6>
          <p>{willingness}</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <button onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack size={50} />
        </button>
        <button onClick={() => navigate('/form-2/comment')}>
          <IoIosArrowRoundForward size={50} />
        </button>
      </div>
    </>
  );
};

export default StepCelebration;
