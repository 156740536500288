const happyFeelings = [
  'Ne pas préciser',
  'Amusé.e',
  'Content.e',
  'Ravi.e',
  'Joyeux.se',
  'Enchanté.e',
  'Euphorique',
  'Fou.folle de joie',
];

export default happyFeelings;
