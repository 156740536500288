/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { ISession } from '~/types/sessions';

const createAspirations = async (data: {
  input: string,
  rewording?: string,
  sessionId: string,
}): Promise<{ session: ISession }> => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/ai/aspirations`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

export {
  createAspirations,
};
