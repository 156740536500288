import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import InputMood from '~/components/InputMood';
import { Textarea } from '~/lib/HooksFormFields';
import styles from './comment.module.scss';

const StepComment = () => {
  const navigate = useNavigate();
  const { control } = useFormContext();

  return (
    <>
      <div className={styles.comment}>
        <h4>
          Comment vous sentez-vous à présent ?
        </h4>
        <div className={styles.containerInput}>
          <InputMood
            name='moodAfter'
            control={control}
            gradient
          />
        </div>
        <p>Un commentaire ?</p>
        <Textarea
          name='comment'
          control={control}
        />
      </div>
      <div className={styles.bottom}>
        <button onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack size={50} />
        </button>
        <button onClick={() => navigate('/form-1/end')}>
          <IoIosArrowRoundForward size={50} />
        </button>
      </div>
    </>
  );
};

export default StepComment;
