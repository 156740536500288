import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';
import styles from './modal.module.scss';

interface ModalRefProps {
  open: () => void,
  close: () => void,
}

const Modal = forwardRef<
ModalRefProps,
{ children: React.ReactNode, handleClose?:() => void }
>(({ children }, ref) => {
    const modalRoot = document.getElementById('modal');
    const [isOpened, setIsOpened] = useState(false);

    useImperativeHandle(ref, () => ({
      open: () => setIsOpened(true),
      close: () => setIsOpened(false),
    }));

    return (
    <>
      {(modalRoot) && (
        <>
          {createPortal(
            <>
                <AnimatePresence>
                  {isOpened && (
                    <motion.div
                      className={styles.modal}
                      initial={{
                        opacity: 0,
                        x: 100,
                      }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: { ease: 'easeInOut', duration: 0.6 },
                      }}
                      exit={{
                        opacity: 0,
                        x: -100,
                        transition: { ease: 'easeInOut', duration: 0.6 },
                      }}
                    >
                      {children}
                    </motion.div>
                  )}
                </AnimatePresence>
            </>,
            modalRoot,
          )}
        </>
      )}
    </>
    );
  });

export default Modal;
