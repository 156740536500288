const feelings = [
  'Confus.e',
  'Serein.e',
  'Fatigué.e',
  'Joyeux.se',
  'Surpris.e',
  'Dégouté.e',
  'Triste',
  'Inquiet.e',
  'En colère',
];

export default feelings;
