import React, { useEffect, useCallback } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { useQueryClient } from '@tanstack/react-query';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useUpdateSession } from '~/hooks/sessions';
import { ISession } from '~/types/sessions';

const ContextForm = ({ children } : { children: React.ReactNode }) => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateSession,
  } = useUpdateSession();

  const methods = useForm({
    defaultValues: {},
  });

  const submit = useCallback(async (data: FieldValues) => {
    const session: ISession | undefined = queryClient.getQueryData(['session']);
    if (!session?._id) return;
    await updateSession({
      _id: session._id,
      ...data,
    });
  }, []);

  const debouncedSubmit = useDebounceCallback(submit, 1500);

  // function to watch change on all field
  const submitOnChange = () => methods.watch(() => {
    methods.handleSubmit(debouncedSubmit)();
  });

  useEffect(() => {
    // const subscription = watch((data) => {
    const subscription = submitOnChange();
    return () => subscription.unsubscribe();
  }, [submitOnChange]);

  return (
    <FormProvider {...methods}>
      {children}
    </FormProvider>
  );
};

export default ContextForm;
