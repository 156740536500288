import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useUpdateProfile, useUserProfile } from '~/hooks/auth';
import InputMood from '~/components/InputMood';
import styles from './mood.module.scss';

const Mood = () => {
  const navigate = useNavigate();
  const { data: user } = useUserProfile();
  const {
    mutateAsync: updateProfile,
  } = useUpdateProfile();
  const { control } = useForm();

  const handleSelectMoods = async (mood: string) => {
    if (!user) return;
    try {
      await updateProfile({
        moods: [
          ...(user.moods || []),
          {
            date: format(new Date(), 'dd-MM-yyyy HH:mm', { locale: fr }),
            mood,
          },
        ],
      });
      navigate('/home');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.mood}>
      <div className={styles.content}>
        <h1>Bonjour {user?.profile.firstName}</h1>
        <p>Comment vous sentez-vous à cet instant ?</p>
        <span >
          <InputMood
            name='mood'
            control={control}
            onClick={(mood: string) => handleSelectMoods(mood)}
          />
        </span>
      </div>
      <div className={styles.bottom}>
        <NavLink to='/home'>Passer cette étape</NavLink>
      </div>
    </div>
  );
};

export default Mood;
