const ressources = [{
  title: 'Nom de la ressource 1',
  short: 'Lorem ipsum dolor sit amet consectetur. Sagittis urna arcu netus proin pellentesque.',
  text: `
  Lorem ipsum dolor sit amet consectetur. Sagittis urna arcu netus proin pellentesque. Faucibus nullam pellentesque consequat nec eget. Molestie elit viverra egestas mattis. Urna commodo consequat cum viverra aenean. Nunc nam vestibulum sed pellentesque sodales. Erat sit faucibus mattis facilisis aliquam enim vitae consectetur pellentesque. Scelerisque lorem vitae lacus aliquet. Sed vivamus arcu commodo pharetra. Ut viverra tempor et ultrices phasellus habitant neque venenatis ullamcorper. Sit ipsum egestas consectetur tempor a. Habitant praesent a morbi tempor duis nisl sed adipiscing ante. Dis ridiculus diam rutrum ac sit rutrum maecenas libero. Enim cursus enim sit vitae sit vitae. Sit viverra morbi aliquam quam.\n\n 
  Euismod ultrices turpis lectus donec lorem laoreet. Sit commodo non faucibus feugiat scelerisque. Tristique vestibulum arcu laoreet dictumst. Orci massa etiam amet sed enim suspendisse feugiat. Elit et a bibendum volutpat consequat semper felis. Viverra volutpat id diam id. Sit elit commodo nisl imperdiet sit mi sed id. Curabitur elit et gravida sodales vitae. Facilisis et morbi elementum volutpat tristique pellentesque amet. Neque aenean convallis in accumsan et ullamcorper nulla imperdiet. Amet donec tempor risus sapien eget. Sagittis ac vel praesent tincidunt. Velit donec ut in quam. Tristique commodo lectus at ut leo nulla non leo mauris. Commodo aliquam faucibus pharetra non ut pellentesque.
  `,
  image: '',
}, {
  title: 'Nom de la ressource 2',
  short: 'Lorem ipsum dolor sit amet consectetur. Sagittis urna arcu netus proin pellentesque.',
  text: `
  Lorem ipsum dolor sit amet consectetur. Sagittis urna arcu netus proin pellentesque. Faucibus nullam pellentesque consequat nec eget. Molestie elit viverra egestas mattis. Urna commodo consequat cum viverra aenean. Nunc nam vestibulum sed pellentesque sodales. Erat sit faucibus mattis facilisis aliquam enim vitae consectetur pellentesque. Scelerisque lorem vitae lacus aliquet. Sed vivamus arcu commodo pharetra. Ut viverra tempor et ultrices phasellus habitant neque venenatis ullamcorper. Sit ipsum egestas consectetur tempor a. Habitant praesent a morbi tempor duis nisl sed adipiscing ante. Dis ridiculus diam rutrum ac sit rutrum maecenas libero. Enim cursus enim sit vitae sit vitae. Sit viverra morbi aliquam quam.\n\n 
  Euismod ultrices turpis lectus donec lorem laoreet. Sit commodo non faucibus feugiat scelerisque. Tristique vestibulum arcu laoreet dictumst. Orci massa etiam amet sed enim suspendisse feugiat. Elit et a bibendum volutpat consequat semper felis. Viverra volutpat id diam id. Sit elit commodo nisl imperdiet sit mi sed id. Curabitur elit et gravida sodales vitae. Facilisis et morbi elementum volutpat tristique pellentesque amet. Neque aenean convallis in accumsan et ullamcorper nulla imperdiet. Amet donec tempor risus sapien eget. Sagittis ac vel praesent tincidunt. Velit donec ut in quam. Tristique commodo lectus at ut leo nulla non leo mauris. Commodo aliquam faucibus pharetra non ut pellentesque.
  `,
  image: '',
}, {
  title: 'Nom de la ressource 3',
  short: 'Lorem ipsum dolor sit amet consectetur. Sagittis urna arcu netus proin pellentesque.',
  text: `
  Lorem ipsum dolor sit amet consectetur. Sagittis urna arcu netus proin pellentesque. Faucibus nullam pellentesque consequat nec eget. Molestie elit viverra egestas mattis. Urna commodo consequat cum viverra aenean. Nunc nam vestibulum sed pellentesque sodales. Erat sit faucibus mattis facilisis aliquam enim vitae consectetur pellentesque. Scelerisque lorem vitae lacus aliquet. Sed vivamus arcu commodo pharetra. Ut viverra tempor et ultrices phasellus habitant neque venenatis ullamcorper. Sit ipsum egestas consectetur tempor a. Habitant praesent a morbi tempor duis nisl sed adipiscing ante. Dis ridiculus diam rutrum ac sit rutrum maecenas libero. Enim cursus enim sit vitae sit vitae. Sit viverra morbi aliquam quam.\n\n 
  Euismod ultrices turpis lectus donec lorem laoreet. Sit commodo non faucibus feugiat scelerisque. Tristique vestibulum arcu laoreet dictumst. Orci massa etiam amet sed enim suspendisse feugiat. Elit et a bibendum volutpat consequat semper felis. Viverra volutpat id diam id. Sit elit commodo nisl imperdiet sit mi sed id. Curabitur elit et gravida sodales vitae. Facilisis et morbi elementum volutpat tristique pellentesque amet. Neque aenean convallis in accumsan et ullamcorper nulla imperdiet. Amet donec tempor risus sapien eget. Sagittis ac vel praesent tincidunt. Velit donec ut in quam. Tristique commodo lectus at ut leo nulla non leo mauris. Commodo aliquam faucibus pharetra non ut pellentesque.
  `,
  image: '',
}, {
  title: 'Nom de la ressource 4',
  short: 'Lorem ipsum dolor sit amet consectetur. Sagittis urna arcu netus proin pellentesque.',
  text: `
  Lorem ipsum dolor sit amet consectetur. Sagittis urna arcu netus proin pellentesque. Faucibus nullam pellentesque consequat nec eget. Molestie elit viverra egestas mattis. Urna commodo consequat cum viverra aenean. Nunc nam vestibulum sed pellentesque sodales. Erat sit faucibus mattis facilisis aliquam enim vitae consectetur pellentesque. Scelerisque lorem vitae lacus aliquet. Sed vivamus arcu commodo pharetra. Ut viverra tempor et ultrices phasellus habitant neque venenatis ullamcorper. Sit ipsum egestas consectetur tempor a. Habitant praesent a morbi tempor duis nisl sed adipiscing ante. Dis ridiculus diam rutrum ac sit rutrum maecenas libero. Enim cursus enim sit vitae sit vitae. Sit viverra morbi aliquam quam.\n\n 
  Euismod ultrices turpis lectus donec lorem laoreet. Sit commodo non faucibus feugiat scelerisque. Tristique vestibulum arcu laoreet dictumst. Orci massa etiam amet sed enim suspendisse feugiat. Elit et a bibendum volutpat consequat semper felis. Viverra volutpat id diam id. Sit elit commodo nisl imperdiet sit mi sed id. Curabitur elit et gravida sodales vitae. Facilisis et morbi elementum volutpat tristique pellentesque amet. Neque aenean convallis in accumsan et ullamcorper nulla imperdiet. Amet donec tempor risus sapien eget. Sagittis ac vel praesent tincidunt. Velit donec ut in quam. Tristique commodo lectus at ut leo nulla non leo mauris. Commodo aliquam faucibus pharetra non ut pellentesque.
  `,
  image: '',
}];

export default ressources;
