/* eslint-disable max-len */
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io';
import logo from '~/assets/images/logo.svg';
// import ressources from './data/ressources-data';
import thumbnail1 from '~/assets/images/minia1.png';
import thumbnail2 from '~/assets/images/minia2.png';
import thumbnail3 from '~/assets/images/minia3.png';
import styles from './ressources.module.scss';

const Ressources = () => {
  const navigate = useNavigate();
  const videoRef = useRef<any>();

  const handleClick = () => {
    videoRef.current.requestFullscreen();
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank')?.focus();
  };

  return (
    <div className={styles.ressources}>
      <div className={styles.content}>
        <div className={styles.header}>
          <img src={logo} />
          <div className={styles.title}>
            <button onClick={() => navigate(-1)} className='invisible'>
              <IoIosArrowRoundBack size={40} />
            </button>
            <h4>Nos ressources</h4>
          </div>
        </div>

        <div className={styles.listRessources}>
          <div className={styles.ressource} onClick={() => handleClick()}>
            <div className={styles.image}>
              {/* <img src={image} /> */}
              <video controls={false} width="250" ref={videoRef}>
                <source src={`${process.env.PUBLIC_URL}/assets/Fontamentaux_IE_et_IR.mp4`} type="video/mp4" />
              </video>
            </div>
            <div className={styles.contentRessource}>
              <h5>Vidéo sur les fondamentaux de l&apos;intelligence émotionnelle et relationnelle</h5>
              <p>Présentée par Anne, co-fondatrice</p>
            </div>
          </div>
          <div
            className={styles.ressource}
            onClick={() => openInNewTab('https://www.loom.com/share/23ca82e660454355beb8b918179b42e4?sid=d6200e92-9fb7-4511-87c1-aba159c112b2')}
          >
            <div className={styles.image}>
              <img src={thumbnail1} />
            </div>
            <div className={styles.contentRessource}>
              <h5>Exemple réel d&apos;une contrariété abordée sur HAY</h5>
              <p>Réalisé par Eric, co-fondateur</p>
            </div>
          </div>
          <div
            className={styles.ressource}
            onClick={() => openInNewTab('https://www.loom.com/share/7e3bb22f2fbc44c5a59ea1c8db351023?sid=98e6875f-bbbf-42b6-91c7-fd51caa6f41e')}
          >
            <div className={styles.image}>
              <img src={thumbnail2} />
            </div>
            <div className={styles.contentRessource}>
              <h5>Exemple réel d&apos;une satisfaction abordée sur HAY</h5>
              <p>Réalisé par Eric, co-fondateur</p>
            </div>
          </div>
          <div
            className={styles.ressource}
            onClick={() => openInNewTab('https://www.loom.com/share/7e3bb22f2fbc44c5a59ea1c8db351023?sid=98e6875f-bbbf-42b6-91c7-fd51caa6f41e')}
          >
            <div className={styles.image}>
              <img src={thumbnail3} />
            </div>
            <div className={styles.contentRessource}>
              <h5>Exemple de clarification d&apos;un ressenti avec HAY</h5>
              <p>Réalisé par Eric, co-fondateur</p>
            </div>
          </div>
          {/* {ressources.map(({ title, image, short } : { title: string, image: string, short: string }, i: number) => (
            <div key={`ressource-${i}`} className={styles.ressource} onClick={() => navigate(`/ressources/${i}`)}>
              <div className={styles.image}>
                <img src={image} />
              </div>
              <div className={styles.contentRessource}>
                <h5>{title}</h5>
                <p>{short}</p>
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default Ressources;
