import React, { useRef, useEffect, useCallback } from 'react';
import {
  useNavigate,
  Routes, Route,
} from 'react-router-dom';
import Layout from './components/Layout';
import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import { useUserProfile } from './hooks/auth';
import IntroRoutes from './pages/intro-routes';
import Intro from './pages/intro-routes/intro';
import Mood from './pages/intro-routes/mood';
import Home from './pages/intro-routes/home';
import Ressources from './pages/intro-routes/ressources';
import RessourcesDetail from './pages/intro-routes/ressources-detail';

// P1
import Form1 from './pages/form1';
import Form1Situation from './pages/form1/situation';
import Form1Feelings from './pages/form1/feelings';
import Form1OptionalFeelings from './pages/form1/optional-feelings';
import Form1Quote from './pages/form1/quote';
import Form1Aspirations from './pages/form1/aspirations';
import Form1Willingness from './pages/form1/willingness';
import Form1Ideas from './pages/form1/ideas';
import Form1Formulations from './pages/form1/formulations';
import Form1Celebration from './pages/form1/celebration';
import Form1Comment from './pages/form1/comment';
import Form1End from './pages/form1/end';

// P2
import Form2 from './pages/form2';
import Form2Feelings from './pages/form2/feelings';
import Form2OptionalFeelings from './pages/form2/optional-feelings';
import Form2Situation from './pages/form2/situation';
import Form2Quote from './pages/form2/quote';
import Form2Aspirations from './pages/form2/aspirations';
import Form2Willingness from './pages/form2/willingness';
import Form2Formulations from './pages/form2/formulations';
import Form2Ideas from './pages/form2/ideas';
import Form2Celebration from './pages/form2/celebration';
import Form2Comment from './pages/form2/comment';
import Form2End from './pages/form2/end';

import SplashScreen from './components/SplashScreen';

const Redirect = () => {
  const navigate = useNavigate();
  const { data: user } = useUserProfile();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!user && !token) {
      navigate('/login');
    }
  }, []);

  return (
    <div style={{
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <p>Loading...</p>
    </div>
  );
};

const AppRoutes = () => {
  type SplashScreenHandler = React.ElementRef<typeof SplashScreen>;
  const splashScreenRef = useRef<SplashScreenHandler>(null);
  const { data: user } = useUserProfile();

  const showSplashScreen = useCallback(() => {
    console.log('showSplashScreen');
    if (splashScreenRef.current) splashScreenRef.current.open();
  }, [splashScreenRef.current]);

  return (
    <>
      <SplashScreen ref={splashScreenRef} />
      <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      {user && (
        <Route path='*' element={
          <Layout key='app'>
            <Routes>
              <Route path='/*' element={<IntroRoutes />}>
                <Route index element={<Intro />} />
                <Route path='mood' element={<Mood />} />
                <Route path='home' element={<Home />} />
                <Route path='ressources' element={<Ressources />} />
                <Route path='ressources/:ressourceId' element={<RessourcesDetail />} />
              </Route>
              <Route path='/form-1' element={<Form1 />}>
                <Route index element={<Form1Situation />} />
                <Route path='feelings' element={<Form1Feelings />} />
                <Route path='optional-feelings' element={<Form1OptionalFeelings />} />
                <Route path='quote' element={<Form1Quote />} />
                <Route path='aspirations' element={<Form1Aspirations />} />
                <Route path='willingness' element={<Form1Willingness />} />
                <Route path='ideas' element={<Form1Ideas />} />
                <Route path='formulations' element={<Form1Formulations />} />
                <Route path='celebration' element={<Form1Celebration />} />
                <Route path='comment' element={<Form1Comment />} />
                <Route path='end' element={<Form1End showSplashScreen={showSplashScreen} />} />
              </Route>
              <Route path='/form-2' element={<Form2 />}>
                <Route index element={<Form2Feelings />} />
                <Route path='optional-feelings' element={<Form2OptionalFeelings />} />
                <Route path='situation' element={<Form2Situation />} />
                <Route path='quote' element={<Form2Quote />} />
                <Route path='aspirations' element={<Form2Aspirations />} />
                <Route path='willingness' element={<Form2Willingness />} />
                <Route path='formulations' element={<Form2Formulations />} />
                <Route path='ideas' element={<Form2Ideas />} />
                <Route path='celebration' element={<Form2Celebration />} />
                <Route path='comment' element={<Form2Comment />} />
                <Route path='end' element={<Form2End />} />
              </Route>
            </Routes>
          </Layout>
        }/>
      )}
      <Route path='*' element={<Redirect />} />
    </Routes>
    </>
  );
};

export default AppRoutes;
