import React, {
  ForwardRefRenderFunction,
  useRef, forwardRef, useImperativeHandle,
} from 'react';

import Modal from '~/lib/Modal';
import styles from './modal-help.module.scss';

export type ModalTimeslotExportHandle = {
  open: () => void,
  close: () => void;
};

const ModalTimeslots: ForwardRefRenderFunction<
ModalTimeslotExportHandle, { handleClose?: () => void }> = ({
  handleClose,
}, ref) => {
  const modalRef = useRef<any>(null);
  useImperativeHandle(ref, () => ({
    open: () => modalRef.current.open(),
    close: () => modalRef.current.close(),
  }), []);

  return (
    <Modal
      ref={modalRef}
      handleClose={() => {
        if (typeof handleClose === 'function') handleClose();
      }}
    >
      <div className={styles.help}>
        <ul>
          <li>
            Etre précis.e et factuel.le vous permettra d&apos;obtenir de
           la clarté et d&apos;éviter une interprétation des faits.
           Essayez, et si nécessaire, nous vous aiderons à le reformuler
          </li>
          <li>
          Si plusieurs situations vous viennent en tête,
           nous vous invitons à en choisir une et à refaire ensuite
            le parcours avec les autres situations
          </li>
          <li>
          La description est volontairement limitée à 500 caractères pour se concentrer sur
           un seul fait.
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default forwardRef(ModalTimeslots);
